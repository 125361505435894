import React, { InputHTMLAttributes, useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | null | boolean;
  touched?: boolean;
  label?: string;
  type?: string;
  placeholder?: string;
  rowInput?: boolean;
}

const Input: React.FC<Props> = ({
                                  error,
                                  touched,
                                  label,
                                  type = "text",
                                  placeholder = "",
                                  rowInput,
                                  ...props
                                }) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const renderInput = () => (
    <div className="input-group">
      <input
        type={type === 'password' && showPassword ? 'text' : type}
        placeholder={placeholder}
        className={clsx(
          "form-control form-control-solid form-control-sm",
          { "is-invalid": touched && error },
          {
            "is-valid": touched && !error
          }
        )}
        value={props.value ?? ""}
        {...props}
      />
      {type === 'password' && (
        <div className="input-group-append">
          <span className="input-group-text cursor-pointer" onClick={toggleShowPassword} style={{borderRadius: 0, borderColor: '#F5F8FA'}}>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} color="#808080ab" />
          </span>
        </div>
      )}
    </div>
  );

  const renderError = () => (
    <div className="fv-plugins-message-container">
      <div className="fv-help-block text-danger">
        <span role="alert">{error}</span>
      </div>
    </div>
  );

  if (rowInput) {
    return (
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <label className="col-form-label fs-6 fw-bolder text-dark">{label}</label>
        </div>
        <div className="col-md-9 col-sm-12">
          {renderInput()}
          {touched && error && renderError()}
        </div>
      </div>
    );
  }
  return (
    <div className="w-full">
      {label && <label className="col-form-label fw-bolder text-dark">{label}</label>}
      {renderInput()}
      {touched && error && renderError()}
    </div>
  );
};

export { Input };
