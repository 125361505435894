import {IUserAction, IUserState} from 'interfaces'
import {SET_USERS} from 'redux/actions/user-action'

const initialState: IUserState = {
  users: {data: [], totalRecordCount: 0, pageNumber: 1, pageSize: 10, activeUsers: 0, inactiveUsers: 0}
}

const reducer = (state = initialState, action: IUserAction) => {
  switch (action.type) {
    case SET_USERS:
      return {...state, users: action.payload}

    default:
      return state
  }
}

export default reducer
